var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card p-shadow-6"},[_c('h1',[_vm._v("Marcação de Horas")]),_c('Toast',{style:({ width: _vm.$isMobile() ? '85vw' : '' })}),_c('ConfirmDialog'),_c('Dialog',{style:({ width: _vm.$isMobile() ? '100vw' : '60vw' }),attrs:{"id":"'dialog-hours'","visible":_vm.showTaskForm,"modal":true,"header":this.userTask != null && this.userTask.action === 'update'
        ? `Atualizar ${
            this.userTask.isBooking ? 'Indisponibilidade' : 'Tarefa'
          }`
        : 'Criar Tarefa/Indisponibilidade'},on:{"update:visible":function($event){_vm.showTaskForm=$event}}},[_c('TaskForm',{attrs:{"task":_vm.userTask,"interventionTypes":_vm.interventionTypes},on:{"submitTaskForm":_vm.taskFormSubmited,"cancelTaskForm":_vm.taskFormCanceled}})],1),_c('Dialog',{style:({ width: _vm.$isMobile() ? '100vw' : '35vw' }),attrs:{"id":"'calendarGoTo'","visible":_vm.calendarGoTo,"modal":true,"header":'Ir para dia'},on:{"update:visible":function($event){_vm.calendarGoTo=$event}}},[_c('div',{staticClass:"p-col-12 p-text-center"},[_c('v-calendar',{on:{"dayclick":_vm.goDate}})],1)]),((_vm.loggedUser.isEsa && _vm.myUsers && _vm.myUsers.length > 1)
    || _vm.can('unlockDayHours'))?_c('div',{staticClass:"p-text-right"},[_vm._v(" Visualizar: "),_c('Dropdown',{staticClass:"form-control",attrs:{"name":"myUsersList","inputId":'myUsersList',"options":_vm.myUsers,"optionLabel":'username',"optionValue":'id',"dataKey":'id',"filter":true},on:{"input":_vm.userChanged},model:{value:(_vm.userId),callback:function ($$v) {_vm.userId=$$v},expression:"userId"}})],1):_vm._e(),_c('Loading',{attrs:{"active":_vm.isLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('Loading',{attrs:{"active":_vm.firstload,"is-full-page":false},on:{"update:active":function($event){_vm.firstload=$event}}}),_c('FullCalendar',{ref:"fullCalendar",attrs:{"options":_vm.calendarOptions},scopedSlots:_vm._u([{key:"eventContent",fn:function(arg){return [(arg.event._def.extendedProps.isHoliday)?_c('div',{staticClass:"position-fixed"},[_c('div',[_c('b',[_vm._v("Feriado:")]),_c('br'),_vm._v(" "+_vm._s(arg.event.title)+" ")])]):(arg.event._def.extendedProps.inVacation)?_c('div',{staticClass:"position-fixed"},[_c('div',{staticStyle:{"color":"#000000"}},[_c('b',[_vm._v("Férias")]),_c('br'),_vm._v(" "+_vm._s(arg.event.title)+" ")])]):(arg.event._def.extendedProps.missed)?_c('div',{staticClass:"position-fixed"},[_c('div',[_c('b',[_vm._v("Falta:")]),_vm._v(" "+_vm._s(arg.event.title))])]):_c('div',[_c('div',{staticClass:"p-text-right"},[(arg.event._def.extendedProps.taskInfo.isBooking)?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
              '<div>' +
              arg.timeText +
              '<br />' +
              '<b>Indisponível</b>' +
              '</div>'
            ),expression:"\n              '<div>' +\n              arg.timeText +\n              '<br />' +\n              '<b>Indisponível</b>' +\n              '</div>'\n            "}],staticClass:"pi pi-info-circle p-mr-1"}):_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
              '<div>' +
              arg.timeText +
              '<br />' +
              '<b>Pat: </b>' +
              arg.event._def.extendedProps.taskInfo.pat_number +
              '<br />' +
              '<b>Título: </b>' +
              arg.event._def.extendedProps.taskInfo.pat_title +
              '</div>' +
              '<br />' +
              '<b>Trabalho efectuado: </b>' +
              arg.event._def.extendedProps.taskInfo.resume +
              '</div>'
            ),expression:"\n              '<div>' +\n              arg.timeText +\n              '<br />' +\n              '<b>Pat: </b>' +\n              arg.event._def.extendedProps.taskInfo.pat_number +\n              '<br />' +\n              '<b>Título: </b>' +\n              arg.event._def.extendedProps.taskInfo.pat_title +\n              '</div>' +\n              '<br />' +\n              '<b>Trabalho efectuado: </b>' +\n              arg.event._def.extendedProps.taskInfo.resume +\n              '</div>'\n            "}],staticClass:"pi pi-info-circle p-mr-1"}),(
              _vm.getSelectedUser().permissionChangeMe &&
              arg.isDraggable &&
              arg.event._def.extendedProps.taskInfo.isBooking == undefined
            )?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Copiar tarefa'),expression:"'Copiar tarefa'"}],staticClass:"pi pi-clone p-mr-1",on:{"click":function($event){return _vm.cloneTask(arg.event.id)}}}):_vm._e(),(_vm.getSelectedUser().permissionChangeMe && arg.isDraggable)?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Editar tarefa'),expression:"'Editar tarefa'"}],staticClass:"pi pi-pencil p-mr-1",on:{"click":function($event){return _vm.updateFromForm(arg.event.id)}}}):_vm._e(),(_vm.getSelectedUser().permissionChangeMe && !arg.isDraggable)?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Ver tarefa'),expression:"'Ver tarefa'"}],staticClass:"pi pi-eye p-mr-1",on:{"click":function($event){return _vm.updateFromForm(arg.event.id)}}}):_vm._e(),(
              (_vm.loggedUser.isEsa &&
                _vm.getSelectedUser().permissionChangeMe &&
                arg.isDraggable) ||
              arg.event._def.extendedProps.canDelete
            )?_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:('Eliminar tarefa'),expression:"'Eliminar tarefa'"}],staticClass:"pi pi-trash p-mr-1",on:{"click":function($event){return _vm.deleteTask(
                arg.event.id,
                arg.event._def.extendedProps.taskInfo.typeId
              )}}}):_vm._e()]),_c('div',{staticClass:"text-ellipsis"},[_vm._v(_vm._s(arg.timeText))]),(arg.event._def.extendedProps.taskInfo.isBooking == true)?_c('div',[_c('b',[_vm._v("Indisponível")])]):_c('div',[_c('div',{staticClass:"text-ellipsis"},[_c('b',[_vm._v("Pat:")]),_vm._v(" "+_vm._s(arg.event._def.extendedProps.taskInfo.pat_number)+" ")]),(arg.event._def.extendedProps.taskInfo.pat_title != '')?_c('div',{staticClass:"text-ellipsis"},[_c('b',[_vm._v("Título: ")]),_vm._v(" "+_vm._s(arg.event._def.extendedProps.taskInfo.pat_title)+" ")]):_vm._e()])])]}},{key:"dayHeaderContent",fn:function(arg){return [_c('span',{staticClass:"p-md-10 p-text-left"},[_vm._v(" "+_vm._s(arg.text)+" ")]),_c('span',{staticClass:"p-md-2 p-text-right"},[_c('i',{staticClass:"day-header-status pi pi-lock-open",on:{"click":function($event){return _vm.toogleLocked(arg.date)}}})])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }