<template>
  <div class="card p-shadow-6">
    <h1>Marcação de Horas</h1>

    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog />
    <Dialog
      id="'dialog-hours'"
      :visible.sync="showTaskForm"
      :style="{ width: $isMobile() ? '100vw' : '60vw' }"
      :modal="true"
      :header="
        this.userTask != null && this.userTask.action === 'update'
          ? `Atualizar ${
              this.userTask.isBooking ? 'Indisponibilidade' : 'Tarefa'
            }`
          : 'Criar Tarefa/Indisponibilidade'
      "
    >
      <TaskForm
        :task="userTask"
        :interventionTypes="interventionTypes"
        v-on:submitTaskForm="taskFormSubmited"
        v-on:cancelTaskForm="taskFormCanceled"
      />
    </Dialog>

    <Dialog
      id="'calendarGoTo'"
      :visible.sync="calendarGoTo"
      :style="{ width: $isMobile() ? '100vw' : '35vw' }"
      :modal="true"
      :header="'Ir para dia'"
    >
      <div class="p-col-12 p-text-center">
        <v-calendar @dayclick="goDate" />
      </div>
    </Dialog>

    <div
      v-if="(loggedUser.isEsa && myUsers && myUsers.length > 1)
      || can('unlockDayHours')"
      class="p-text-right"
    >
      Visualizar:
      <Dropdown
        name="myUsersList"
        class="form-control"
        v-model="userId"
        :inputId="'myUsersList'"
        :options="myUsers"
        :optionLabel="'username'"
        :optionValue="'id'"
        :dataKey="'id'"
        :filter="true"
        @input="userChanged"
      />
    </div>
    <Loading :active.sync="isLoading" :is-full-page="false"></Loading>
    <Loading :active.sync="firstload" :is-full-page="false"></Loading>
    <FullCalendar ref="fullCalendar" :options="calendarOptions">
      <template v-slot:eventContent="arg">
        <div
          v-if="arg.event._def.extendedProps.isHoliday"
          class="position-fixed"
        >
          <div>
            <b>Feriado:</b><br />
            {{ arg.event.title }}
          </div>
        </div>
        <div
          v-else-if="arg.event._def.extendedProps.inVacation"
          class="position-fixed"
        >
          <div style="color: #000000">
            <b>Férias</b>
            <br />
            {{ arg.event.title }}
          </div>
        </div>
        <div
          v-else-if="arg.event._def.extendedProps.missed"
          class="position-fixed"
        >
          <div><b>Falta:</b> {{ arg.event.title }}</div>
        </div>
        <div v-else>
          <div class="p-text-right">
            <i
              v-if="arg.event._def.extendedProps.taskInfo.isBooking"
              class="pi pi-info-circle p-mr-1"
              v-tooltip="
                '<div>' +
                arg.timeText +
                '<br />' +
                '<b>Indisponível</b>' +
                '</div>'
              "
            ></i>
            <i
              v-else
              class="pi pi-info-circle p-mr-1"
              v-tooltip="
                '<div>' +
                arg.timeText +
                '<br />' +
                '<b>Pat: </b>' +
                arg.event._def.extendedProps.taskInfo.pat_number +
                '<br />' +
                '<b>Título: </b>' +
                arg.event._def.extendedProps.taskInfo.pat_title +
                '</div>' +
                '<br />' +
                '<b>Trabalho efectuado: </b>' +
                arg.event._def.extendedProps.taskInfo.resume +
                '</div>'
              "
            ></i>
            <i
              class="pi pi-clone p-mr-1"
              v-if="
                getSelectedUser().permissionChangeMe &&
                arg.isDraggable &&
                arg.event._def.extendedProps.taskInfo.isBooking == undefined
              "
              v-on:click="cloneTask(arg.event.id)"
              v-tooltip="'Copiar tarefa'"
            ></i>
            <i
              class="pi pi-pencil p-mr-1"
              v-if="getSelectedUser().permissionChangeMe && arg.isDraggable"
              v-on:click="updateFromForm(arg.event.id)"
              v-tooltip="'Editar tarefa'"
            ></i>
            <i
              class="pi pi-eye p-mr-1"
              v-if="getSelectedUser().permissionChangeMe && !arg.isDraggable"
              v-on:click="updateFromForm(arg.event.id)"
              v-tooltip="'Ver tarefa'"
            ></i>
            <i
              class="pi pi-trash p-mr-1"
              v-if="
                (loggedUser.isEsa &&
                  getSelectedUser().permissionChangeMe &&
                  arg.isDraggable) ||
                arg.event._def.extendedProps.canDelete
              "
              v-on:click="
                deleteTask(
                  arg.event.id,
                  arg.event._def.extendedProps.taskInfo.typeId
                )
              "
              v-tooltip="'Eliminar tarefa'"
            ></i>
          </div>
          <div class="text-ellipsis">{{ arg.timeText }}</div>
          <div v-if="arg.event._def.extendedProps.taskInfo.isBooking == true">
            <b>Indisponível</b>
          </div>
          <div v-else>
            <div class="text-ellipsis">
              <b>Pat:</b> {{ arg.event._def.extendedProps.taskInfo.pat_number }}
            </div>
            <div
              v-if="arg.event._def.extendedProps.taskInfo.pat_title != ''"
              class="text-ellipsis"
            >
              <b>Título: </b>
              {{ arg.event._def.extendedProps.taskInfo.pat_title }}
            </div>
          </div>
        </div>
      </template>
      <template v-slot:dayHeaderContent="arg">
        <span class="p-md-10 p-text-left">
          {{ arg.text }}
        </span>
        <span class="p-md-2 p-text-right">
          <i
            class="day-header-status pi pi-lock-open"
            v-on:click="toogleLocked(arg.date)"
          ></i>
        </span>
      </template>
    </FullCalendar>
  </div>
</template>

<script>
import taskForm from "./TaskForm";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import ptLocale from "@fullcalendar/core/locales/pt";
import { getOnlyDate, hexToRGB, getOnlyTime } from "../helpers/helpers";
import task from "../models/task";
import Task from "../models/task";
import taskService from "../services/task.service";
import interventionService from "../services/intervention.service";
import userService from "../services/user.service";
import bookingService from "../services/booking.service";

import loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "hours",
  components: {
    TaskForm: taskForm,
    Loading: loading,
  },
  data() {
    return {
      userTask: null,
      showTaskForm: false,
      userId: null,
      interventionTypes: [],
      calendarApi: null,
      tasksInCalendar: [],
      myUsers: [],
      calendarOptions: null,
      viewOtherUsers: false,
      calendarGoTo: false,
      isLoading: true,
      firstload: true,
    };
  },
  beforeCreate() {
    userService.getMyUsers().then((response) => {
      this.myUsers = response;
    });
  },
  created() {
    this.userId = this.$store.state.auth.user.id;
    this.getInterventionTypes();
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user;
    },
  },
  beforeMount() {
    this.generateCalendar();
  },
  mounted() {
    this.calendarApi = this.$refs.fullCalendar.getApi();
  },
  methods: {
    generateCalendar() {
      const calendarGoTo = () => (this.calendarGoTo = true);
      this.calendarOptions = {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
        ],
        locale: ptLocale,
        allDaySlot: false,
        selectable: true,
        firstDay: 1,
        height: 700,
        scrollTime: "09:00",
        slotDuration: "00:15",
        slotLabelInterval: "01:00",
        slotLabelFormat: {
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: "short",
        },
        initialView: this.$isMobile() ? "timeGrid" : "timeGridWeek",
        displayEventTime: true,
        displayEventEnd: true,
        editable: true,
        customButtons: {
          showCalendar: {
            text: "Ir para",
            click: function () {
              calendarGoTo();
            },
          },
        },
        headerToolbar: {
          left: "prev,showCalendar,next",
          center: "title",
          right: "",
        },
      };
      const getTasks = (dateStart, dateEnd) =>
        this.getUserTasks(dateStart, dateEnd);

      this.calendarOptions.events = function (info, successCallback) {
        getTasks(getOnlyDate(info.start), getOnlyDate(info.end)).then(
          (tasks) => {
            successCallback(tasks);
          }
        );
      };

      const updateTask = (taskId, taskData) =>
        this.updateTask(taskId, taskData);

      const updateBooking = (bookingId, bookingData) =>
        this.updateBooking(bookingId, bookingData);

      this.calendarOptions.eventResize = function (info) {
        if (info.event.extendedProps.taskInfo.isBooking) {
          let bookingInfo = {
            start: getOnlyTime(info.event.start),
            end: getOnlyTime(info.event.end),
          };

          info.event.extendedProps.taskInfo.start = bookingInfo.start;
          info.event.extendedProps.taskInfo.end = bookingInfo.end;
          updateBooking(info.event.extendedProps.taskInfo.id, bookingInfo);
        } else {
          let taskInfo = {
            type: info.event.extendedProps.taskInfo.typeId,
            pat_number: info.event.extendedProps.taskInfo.pat_number,
            start: `${getOnlyDate(info.event.start)} ${getOnlyTime(
              info.event.start
            )}`,
            end: `${getOnlyDate(info.event.end)} ${getOnlyTime(
              info.event.end
            )}`,
            from_support: false,
          };
          info.event.extendedProps.taskInfo.start = taskInfo.start;
          info.event.extendedProps.taskInfo.end = taskInfo.end;
          updateTask(info.event.id, taskInfo);
        }
      };

      const validateDayLocked = (day) => {
        let dayLocked = this.dayAreLocked(day);
        if (dayLocked) {
          this.$toast.add({
            severity: "error",
            summary: "O dia está fechado",
            detail: "Não é permitida esta ação",
            life: 3000,
          });
        }
        return dayLocked;
      };
      this.calendarOptions.eventDrop = function (info) {
        if (validateDayLocked(getOnlyDate(info.event.start))) {
          info.revert();
          return;
        }
        if (info.event.extendedProps.taskInfo.isBooking) {
          let bookingInfo = {
            date: getOnlyDate(info.event.start),
            start: getOnlyTime(info.event.start),
            end: getOnlyTime(info.event.end),
          };

          info.event.extendedProps.taskInfo.start = bookingInfo.start;
          info.event.extendedProps.taskInfo.end = bookingInfo.end;
          info.event.extendedProps.taskInfo.date = bookingInfo.date;
          updateBooking(info.event.extendedProps.taskInfo.id, bookingInfo);
        } else {
          let taskInfo = {
            type: info.event.extendedProps.taskInfo.typeId,
            pat_number: info.event.extendedProps.taskInfo.pat_number,
            start: `${getOnlyDate(info.event.start)} ${getOnlyTime(
              info.event.start
            )}`,
            end: `${getOnlyDate(info.event.end)} ${getOnlyTime(
              info.event.end
            )}`,
            from_support: false,
          };
          info.event.extendedProps.taskInfo.start = taskInfo.start;
          info.event.extendedProps.taskInfo.end = taskInfo.end;
          updateTask(info.event.id, taskInfo);
        }
      };

      const addTask = (start, end) => this.createTask(start, end);

      this.calendarOptions.select = function (info) {
        addTask(info.start, info.end);
      };

      const updateTaskForm = (taskId) => this.updateFromForm(taskId);
      this.calendarOptions.eventDidMount = function (eventInfo) {
        eventInfo.el.id = eventInfo.event.id;
        eventInfo.el.ondblclick = function () {
          updateTaskForm(eventInfo.event.id);
        };
      };
    },
    getInterventionTypes() {
      let typesCount = 0;
      return interventionService
        .getInterventionTypes()
        .then((response) => {
          return (this.interventionTypes = response);
        })
        .then((typeList) => {
          typesCount = typeList.length;
          typeList.forEach((type) => {
            this.isLoading = true;
            interventionService
              .getInterventionsByType(type.id)
              .then((typeProjects) => {
                type["projects"] = typeProjects;
                // this.isLoading = true;
                if (--typesCount <= 0) {
                  this.isLoading = false;
                  this.firstload = false;
                }
              });
          });
        });
    },
    getInterventionTypeId(interventionName) {
      let type = this.interventionTypes.find(
        (x) => x.name === interventionName
      );

      if (type == undefined) {
        return 0;
      }
      return type.id;
    },
    getUserTasks(dateStart, dateEnd) {
      this.isLoading = true;
      this.removeAllTasks();
      return taskService
        .getTasksAndBookings(this.userId, dateStart, dateEnd)
        .then((days) => {
          this.tasksInCalendar = days;
          let userTasks = [];
          let selectedUser = this.getSelectedUser();
          days.forEach((day) => {
            if (day.locked) {
              this.lockDay(day.day, false);
            }
            if (day.isHoliday || day.inVacation || day.missed) {
              let backgroundTask = {
                start: `${day.day} 00:00`,
                end: `${day.day} 23:59`,
                isHoliday: false,
                inVacation: false,
                missed: false,
                display: "background",
              };
              if (day.isHoliday) {
                backgroundTask.title = day.isHoliday;
                backgroundTask.isHoliday = true;
              } else if (day.inVacation) {
                backgroundTask.title = "";
                if (!day.inVacation.approved) {
                  backgroundTask.title = "(Não Aprovada)";
                }
                backgroundTask.inVacation = true;
                backgroundTask.color = "#33ff00";
              } else if (day.missed) {
                backgroundTask.title = day.missed;
                backgroundTask.missed = true;
                backgroundTask.color = "#ff0000";
              }
              userTasks.push(backgroundTask);
            }
            day.tasks
              .filter(
                (task) =>
                  !(
                    task.isBooking != undefined &&
                    task.isBooking == true &&
                    task.type == 1
                  )
              )
              .forEach((task) => {
                task.typeId = this.getInterventionTypeId(task.type);
                userTasks.push({
                  id:
                    task.isBooking != undefined && task.isBooking == true
                      ? `booking-${task.id}`
                      : task.id,
                  title:
                    task.isBooking != undefined && task.isBooking == true
                      ? `Indisponível`
                      : `${task.pat_number} - ${task.pat_title}`,
                  start:
                    task.isBooking != undefined && task.isBooking == true
                      ? `${task.date} ${task.start}`
                      : task.start,
                  end:
                    task.isBooking != undefined && task.isBooking == true
                      ? `${task.date} ${task.end}`
                      : task.end,
                  color:
                    !selectedUser.permissionChangeMe ||
                    day.locked == true ||
                    (task.isBooking != undefined && task.isBooking)
                      ? hexToRGB(task.color, 0.4)
                      : task.color,
                  editable:
                    selectedUser.permissionChangeMe &&
                    !day.locked &&
                    this.isEditable(task),
                  canDelete:
                    selectedUser.permissionChangeMe &&
                    !day.locked &&
                    this.canDelete(task),
                  taskInfo: task,
                });
              });
          });
          this.isLoading = false;
          return userTasks;
        });
    },
    updateTask(taskId, taskNewInfo) {
      return taskService.updatedTask(taskId, taskNewInfo).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro ao gravar",
            detail: "Ocorreu um erro ao gravar a tarefa",
            life: 3000,
          });
        }
        return this.$toast.add({
          severity: "success",
          summary: "Atualizado com sucesso",
          detail: "A tarefa foi atualizada com sucesso",
          life: 3000,
        });
      });
    },
    deleteTask(taskId) {
      let item = this.calendarApi.getEventById(taskId);
      let itemInfo = item._def.extendedProps.taskInfo;
      this.$confirm.require({
        message: `Tem a certeza que deseja eliminar esta ${
          itemInfo.isBooking != undefined && itemInfo.isBooking
            ? "indisponíbilidade"
            : "tarefa"
        }?`,
        icon: "pi pi-exclamation-triangle",
        position: "top",
        accept: () => {
          if (itemInfo.isBooking != undefined && itemInfo.isBooking) {
            return bookingService
              .removeBooking(itemInfo.id)
              .then((response) => {
                if (!response) {
                  return this.$toast.add({
                    severity: "error",
                    summary: `Erro ao eliminar a indisponíbilidade`,
                    detail: `Ocorreu um erro ao eliminar a indisponíbilidade`,
                    life: 3000,
                  });
                }
                item.remove();
                this.$toast.add({
                  severity: "success",
                  summary: `Indisponíbilidade eliminada com sucesso`,
                  detail: "",
                  life: 3000,
                });
              });
          } else {
            let typeId = itemInfo.typeId;

            return taskService.removeTask(taskId, typeId).then((response) => {
              if (!response) {
                this.$toast.add({
                  severity: "error",
                  summary: "Erro ao eliminar a tarefa",
                  detail: "Ocorreu um erro ao eliminar a tarefa",
                  life: 3000,
                });
              }
              item.remove();
              this.$toast.add({
                severity: "success",
                summary: "Tarefa eliminada com sucesso",
                detail: "",
                life: 3000,
              });
            });
          }
        },
      });
    },
    createTask(start, end) {
      this.calendarApi.unselect();
      let startDay = getOnlyDate(start);

      if (!this.getSelectedUser().permissionChangeMe) {
        return this.forbiddenToast();
      }
      if (this.dayAreLocked(startDay)) {
        return this.$toast.add({
          severity: "error",
          summary: "Dia fechado",
          detail: "Não é possível a criação da tarefa",
          life: 3000,
        });
      }
      let endDay = getOnlyDate(end);

      if (startDay !== endDay) {
        return this.$toast.add({
          severity: "error",
          summary: "Data inválida",
          detail: "O dia de ínicio é diferente do dia de fim da tarefa",
          life: 3000,
        });
      }

      this.userTask = new task();
      this.userTask.action = "create";
      this.userTask.day = startDay;
      this.userTask.start = start;
      this.userTask.end = end;
      this.userTask.isBooking = false;
      this.userTask.type = null;
      this.showTaskForm = true;
      this.userTask.taskType = 0;
    },
    taskFormSubmited(task) {
      this.userTasks = null;
      this.showTaskForm = false;
      if (task.day instanceof Date) {
        task.day = getOnlyDate(task.day);
      }
      if (task.start instanceof Date) {
        task.start = getOnlyTime(task.start);
      }
      if (task.end instanceof Date) {
        task.end = getOnlyTime(task.end);
      }

      if (task.isBooking != undefined && task.isBooking == true) {
        //IS BOOKING
        let bookingInfo = {
          date: task.day,
          start: task.start,
          end: task.end,
          resume: task.resume != null ? task.resume : "",
          description: task.description != null ? task.description : "",
          usersSelecteds: task.usersSelecteds,
          note: task.note != null ? task.note : "",
          type: task.type != null ? task.type : 0,
        };

        if (task.bookingId == undefined) {
          //NEW BOOKING
          return this.addBooking(bookingInfo);
        }
        //UPDATE Booking
        return this.updateBooking(task.bookingId, bookingInfo, true);
      } else {
        //Is Task
        let taskInfo = {
          type: task.type,
          pat_number: task.patNumber,
          start: `${task.day} ${task.start}`,
          end: `${task.day} ${task.end}`,
          resume: task.resume != undefined ? task.resume : "",
          description: task.description != undefined ? task.description : "",
        };

        switch (task.action) {
          case "update":
            return this.updateFormSubmited(task.taskId, taskInfo);
          default:
            this.saveNewTask(taskInfo);
        }
      }
    },
    taskFormCanceled() {
      this.userTasks = null;
      this.showTaskForm = false;
    },
    saveNewTask(task) {
      task.user_id = this.userId;
      return taskService
        .createTask(task)
        .then((response) => {
          if (response) {
            response.typeId = this.getInterventionTypeId(response.type);
            this.calendarApi.addEvent({
              id: response.id,
              title: `${response.pat_number} - ${response.pat_title}`,
              start: response.start,
              end: response.end,
              color: response.color,
              editable: this.isEditable(response),
              canDelete: this.canDelete(response),
              taskInfo: response,
            });
            return true;
          }
          return false;
        })
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao gravar",
              detail: "Ocorreu um erro ao gravar a tarefa",
              life: 3000,
            });
          }

          return this.$toast.add({
            severity: "success",
            summary: "Tarefa criada com sucesso",
            detail: "",
            life: 3000,
          });
        });
    },
    updateFromForm(taskId) {
      let task = this.calendarApi.getEventById(taskId);
      let taskInfo = task._def.extendedProps.taskInfo;
      this.userTask = new Task();

      if (taskInfo.isBooking != undefined && taskInfo.isBooking == true) {
        this.userTask.isBooking = true;
        this.userTask.bookingId = taskInfo.id;
        this.userTask.day = taskInfo.date;
        this.userTask.start = new Date(`${taskInfo.date} ${taskInfo.start}`);
        this.userTask.end = new Date(`${taskInfo.date} ${taskInfo.end}`);
        this.userTask.type = taskInfo.type;
        this.userTask.taskType = taskInfo.type;
      } else {
        taskInfo.start = new Date(taskInfo.start);
        taskInfo.end = new Date(taskInfo.end);
        this.userTask.start = taskInfo.start;
        this.userTask.end = taskInfo.end;
        this.userTask.day = getOnlyDate(taskInfo.start);
        this.userTask.isBooking = false;
        this.userTask.localization = taskInfo.localization;
        this.userTask.type = taskInfo.typeId = !undefined ? taskInfo.typeId : 0;
        this.userTask.taskType = 0;
      }

      if (
        this.dayAreLocked(this.userTask.day) ||
        !this.getSelectedUser().permissionChangeMe
      ) {
        this.userTask.action = "view";
      } else if (!this.loggedUser.isEsa && !this.isEditable(taskInfo)) {
        this.userTask.action = "view";
      } else {
        this.userTask.action = "update";
      }
      // this.userTask.action =
      //   this.dayAreLocked(this.userTask.day) ||
      //   !this.getSelectedUser().permissionChangeMe
      //     ? "view"
      //     : "update";
      this.userTask.resume = taskInfo.resume;
      this.userTask.description = taskInfo.description;
      this.userTask.patNumber = taskInfo.pat_number;
      this.userTask.taskId = taskId;
      this.userTask.color = taskInfo.color;
      this.userTask.task_all_info = taskInfo;
      this.showTaskForm = true;
    },
    updateFormSubmited(taskId, taskNewInfo) {
      return taskService.updatedTask(taskId, taskNewInfo).then((response) => {
        if (!response) {
          return this.$toast.add({
            severity: "error",
            summary: "Erro ao gravar",
            detail: "Ocorreu um erro ao gravar a tarefa",
            life: 3000,
          });
        }
        let task = this.calendarApi.getEventById(taskId);
        task.remove();
        response.typeId = this.getInterventionTypeId(response.type);
        this.calendarApi.addEvent({
          id: response.id,
          title: `${response.pat_number} - ${response.pat_title}`,
          start: response.start,
          end: response.end,
          color: response.color,
          editable: this.isEditable(response),
          canDelete: this.canDelete(response),
          taskInfo: response,
        });
        return this.$toast.add({
          severity: "success",
          summary: "Tarefa atualizada com sucesso",
          detail: "",
          life: 3000,
        });
      });
    },
    lockDay(day, lockEvents) {
      let dateHeader = document.querySelector(`[data-date="${day}"]`);
      if (dateHeader) {
        let icone = dateHeader.querySelector("i");
        icone.classList.remove("pi-lock-open");
        icone.classList.add("pi-lock");
        if (lockEvents) {
          icone.classList.add("lockNow");
          this.lockEvents(day);
        }
      }
    },
    lockEvents(day) {
      let events = this.calendarApi.getEvents();
      let dayAux = this.tasksInCalendar.find((x) => x.day === day);
      dayAux.currentStatus = "lock";
      events.forEach((event) => {
        if (getOnlyDate(event.start) === day) {
          event.setProp("startEditable", false);
          event.setProp("durationEditable", false);
          event.setProp("editable", false);
          event.setProp(
            "color",
            hexToRGB(event.extendedProps.taskInfo.color, 0.4)
          );
        }
      });
    },
    unlockDay(day) {
      let dayAux = this.tasksInCalendar.find((x) => x.day === day);

      let dateHeader = document.querySelector(`[data-date="${day}"]`);
      let icone = dateHeader.querySelector("i");
      icone.classList.remove("pi-lock");
      icone.classList.remove("lockNow");
      icone.classList.add("pi-lock-open");

      dayAux.currentStatus = "unlock";
      let events = this.calendarApi.getEvents();

      events.forEach((event) => {
        if (getOnlyDate(event.start) === day) {
          event.setProp("startEditable", true);
          event.setProp("durationEditable", true);
          event.setProp("editable", true);
          event.setProp("color", event.extendedProps.taskInfo.color);
        }
      });
    },
    toogleLocked(day) {
      //Case loged user not permission to change selected user
      if (!this.getSelectedUser().permissionChangeMe) {
        return this.forbiddenToast();
      }
      day = getOnlyDate(day);

      if (this.dayAreLocked(day)) {
        if (this.permissionUnLocked(day)) {
          return userService
            .changeDayStatus(this.userId, day, 0)
            .then((response) => {
              if (!response) {
                return this.$toast.add({
                  severity: "error",
                  summary: "Erro ao gravar",
                  detail: "Ocorreu um erro ao desbloquear o dia",
                  life: 3000,
                });
              }
              return this.unlockDay(day);
            });
        }
        return this.forbiddenToast();
      }

      return userService
        .changeDayStatus(this.userId, day, 1)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao gravar",
              detail: "Ocorreu um erro ao bloquear o dia",
              life: 3000,
            });
          }
          return this.lockDay(day, true);
        });
    },
    dayAreLocked(day) {
      if (day instanceof Date) {
        day = getOnlyDate(day);
      }
      let dayAux = this.tasksInCalendar.find((x) => x.day === day);
      if (
        (dayAux.currentStatus === undefined && dayAux.locked) ||
        dayAux.currentStatus === "lock"
      ) {
        return true;
      }
      return false;
    },
    permissionUnLocked(day) {
      //Case loged user is ESA
      if (this.$store.state.auth.user.isEsa) {
        return true;
      }
      if (this.can("unlockDayHours")) {
        return true;
      }

      //Case loged user not permission to change selected user
      if (!this.getSelectedUser().permissionChangeMe) {
        return false;
      }

      let dayAux = this.tasksInCalendar.find((x) => x.day === day);
      if (dayAux.currentStatus === undefined && dayAux.locked) {
        return false;
      }
      return true;
    },
    userChanged(select) {
      this.userId = select;
      return this.generateCalendar();
    },
    getSelectedUser() {
      let user = this.myUsers.find((x) => x.id === this.userId);

      return user;
    },
    forbiddenToast() {
      return this.$toast.add({
        severity: "error",
        summary: "Não autorizado",
        detail: "Não tem permissão para efetuar esta ação",
        life: 3000,
      });
    },
    goDate(date) {
      this.calendarApi.gotoDate(date.id);
      this.calendarGoTo = false;
    },
    cloneTask(taskId) {
      let task = this.calendarApi.getEventById(taskId);
      let taskInfo = task._def.extendedProps.taskInfo;
      let startDate = new Date(taskInfo.start);
      let endDate = new Date(taskInfo.end);
      let taskCloneInfo = {
        user_id: this.userId,
        type: taskInfo.typeId,
        pat_number: parseInt(taskInfo.pat_number),
        start: `${getOnlyDate(startDate)} ${getOnlyTime(startDate)}`,
        end: `${getOnlyDate(endDate)} ${getOnlyTime(endDate)}`,
        resume: taskInfo.resume,
        description: taskInfo.description,
      };
      this.saveNewTask(taskCloneInfo);
    },
    removeAllTasks() {
      if (!this.calendarApi) {
        return;
      }
      let events = this.calendarApi.getEvents();
      events.forEach((event) => {
        event.remove();
      });
    },
    addBooking(booking) {
      booking.user_id = this.userId;
      bookingService
        .createBooking(booking)
        .then((response) => {
          if (response) {
            response.typeId = this.getInterventionTypeId(response.type);
            this.calendarApi.addEvent({
              id: `booking-${response.id}`,
              title: `Indisponível`,
              type: response.type,
              start: `${response.date} ${response.start}`,
              end: `${response.date} ${response.end}`,
              color: hexToRGB(response.color, 0.4),
              editable: this.isEditable(response),
              canDelete: this.canDelete(response),
              taskInfo: response,
            });
            return true;
          }
          return false;
        })
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao gravar",
              detail: `Ocorreu um erro ao gravar a ${
                booking.type == 1 ? "reserva" : "indisponibilidade"
              }`,
              life: 3000,
            });
          }

          return this.$toast.add({
            severity: "success",
            summary: `${
              booking.type == 1 ? "Reserva" : "Indisponibilidade"
            } criada com sucesso`,
            detail: "",
            life: 3000,
          });
        });
    },
    updateBooking(bookingId, bookingNewInfo, fromForm) {
      return bookingService
        .updatedBooking(bookingId, bookingNewInfo)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro ao gravar",
              detail: "Ocorreu um erro ao gravar a reserva",
              life: 3000,
            });
          }

          if (fromForm != undefined && fromForm == true) {
            let booking = this.calendarApi.getEventById(`booking-${bookingId}`);
            booking.remove();
            this.calendarApi.addEvent({
              id: `booking-${response.id}`,
              title: `Indisponível`,
              type: response.type,
              start: `${response.date} ${response.start}`,
              end: `${response.date} ${response.end}`,
              color: hexToRGB(response.color, 0.4),
              editable: this.isEditable(response),
              canDelete: this.canDelete(response),
              taskInfo: response,
            });
          }
          return this.$toast.add({
            severity: "success",
            summary: "Atualizado com sucesso",
            detail: "A reserva foi atualizada com sucesso",
            life: 3000,
          });
        });
    },
    isEditable(task) {
      if (this.loggedUser.isEsa) {
        return true;
      }
      //Caso de indisponibilidade
      if (
        task.isBooking != undefined &&
        task.isBooking == true &&
        task.type == 2
      ) {
        if (this.can("editUnavailability")) {
          return true;
        }
        return false;
      }
      // if (
      //   task.created_by != this.loggedUser.username &&
      //   !this.can("supportEditTasks")
      // ) {
      //   return false;
      // }
      return true;
    },
    canDelete(task) {
      if (this.loggedUser.isEsa) {
        return true;
      }
      //Caso de indisponibilidade
      if (
        task.isBooking != undefined &&
        task.isBooking == true &&
        task.type == 2
      ) {
        if (this.can("deleteUnavailability")) {
          return true;
        }
        return false;
      }
      if (task.created_by == this.loggedUser.username) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
.fc-event-main {
  background: rgba(255, 255, 255, 0) !important;
  background-color: rgba(255, 255, 255, 0) !important;
  border: 0px !important;
  overflow: hidden;
}

.day-header-status {
  cursor: pointer;
}

.lockNow {
  color: red;
}

.position-fixed {
  position: fixed;
}

.fc-scrollgrid-shrink,
.fc-scrollgrid-shrink + td {
  border-top-width: 3px !important;
}

/* .fc .fc-timegrid-col.fc-day-today {
   background: rgb(0, 0, 255) !important;
}  */

.fc .fc-view-harness td {
  background: rgba(0, 0, 0, 0);
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.p-tooltip {
  z-index: 99;
}

.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
